
import { Component, Vue } from "vue-property-decorator";
import pageBack from "src/components/page-back";
import vtable from "src/components/vtable";
import globalConfig from "src/config/index";

@Component({
  components: {
    pageBack,
    vtable,
  },
})
export default class Login extends Vue {
  private data = [
    {
      type: "体检人的姓名、性别、证件类型、证件号码、年龄、手机号、婚否状况",
      scene: "预约体检服务时",
      objective: "用于同步体检服务信息及关联体检产品信息",
      manner: "后台接口传输",
      name: "体检机构、医院",
      mark: "是",
    },
    {
      type: "收货人的姓名、手机、地址",
      scene: "邮寄体检报告时",
      objective: "用于同步配送信息",
      manner: "后台接口传输",
      name: "服务供应商、快递公司",
      mark: "是",
    },
  ];

  private header = [
    { title: "个人信息类型", prop: "type", width: "130", align: "center" },
    { title: "使用场景", prop: "scene", width: "130", align: "center" },
    { title: "使用目的及功能", prop: "objective", width: "150", align: "center" },
    { title: "共享方式", prop: "manner", width: "100", align: "center" },
    { title: "接受个人信息的第三方名称", prop: "name", width: "110", align: "center" },
    { title: "是否去标识化传输", prop: "mark", width: "100", align: "center" },
  ];
  /**
   * 返回
   */
  private customFn() {
    this.$emit("close-share");
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }
}
